<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
              v-model="limit"
              :options="limitOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
<!--              <b-form-input
                v-model="searchKey"
                class="d-inline-block mr-1"
                placeholder="搜索关键字..."
              />-->
              <b-button
                  variant="primary"
                  @click="completeOutbound()"
                  style="margin: 5px"
              >
                <span class="text-nowrap">出库完成</span>
              </b-button>

              <b-button
                variant="primary"
                :to="{ name: 'apps-allotoutboundoutbound-edit' ,query: {id: this.allot_outbound_id}}"
              >
                <span class="text-nowrap">添加</span>
              </b-button>

              <b-button
                  variant="primary"
                  :to="{ name: 'apps-allotoutbound-warehouse'}"
                  style="margin: 5px"
              >
                <span class="text-nowrap">返回</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(id)="data">
          <b-link
              :to="{ name: 'apps-allotoutboundoutbound-edit', query: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
          >
            #{{ data.item.id }}
          </b-link>
        </template>

        <!-- Column: Type -->


        <template #cell(status)="data">
          {{getCodeLabel('allot_status_outbound', data.item.status)}}
        </template>


        <template #cell(usedTrueQty)="data">
          {{data.item.ext['usedTrueQty']}}
        </template>

        <template #cell(car_detail)="data">
          {{getCodeLabel('car_detail', data.item.car_detail)}}
        </template>

        <template #cell(car_type)="data">
          {{getCodeLabel('car_type', data.item.car_type)}}
        </template>

        <template #cell(update_time)="data">
          {{ toTime(data.item.update_time) }}
        </template>

        <template #cell(updator)="data">
          {{ getCodeLabel('user', data.item.updator) }}
        </template>

        <template #cell(outbound_no)="data" >
          <a target="_blank" :href="toURL(data.item)" style="color: deepskyblue"  v-if="data.item.status==2">
            {{data.item.outbound_no}}
          </a>
          <div v-if="data.item.status==1">
            {{data.item.outbound_no}}
          </div>
        </template>


        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
            @click="changeState(data.item)"
          >
          <b-badge
            pill
            :variant="`light-$ {getCodeColor('state', data.item.state)}`"

          >
            {{getCodeLabel('state', data.item.state)}}
          </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>
<!--单菜单-->

<!--多菜单-->
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item v-if="data.item.status==1" @click="editItem(data.item)" >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.status==1" @click="submitItem(data.item)">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">提交</span>
            </b-dropdown-item>

            <b-dropdown-item  v-if="data.item.status==1||data.item.status==2"  @click="printItemPre(data.item.outbound_id)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">打印</span>
            </b-dropdown-item>

<!--            <b-dropdown-item  v-if="data.item.status==2"  @click="resoleProduce(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">资产回收</span>
            </b-dropdown-item>-->

            <!--装卸、物流费-->
            <b-dropdown-item @click="showChargeModal(data.item,1)" v-if="data.item.status === 2">
              <feather-icon icon="ToolIcon"/>
              <span class="align-middle ml-50">生成装卸费</span>
            </b-dropdown-item>
            <b-dropdown-item @click="showChargeModal(data.item,2)" v-if="data.item.status === 2">
              <feather-icon icon="ToolIcon"/>
              <span class="align-middle ml-50">生成物流费</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      <b-modal
          id="pdfModal"
          centered
          title="PDF"
          ref="pdfModal"
          hide-footer
      >
        <attachment-upload theme="preview"
                           attachment_id="attachments"
                           :id="pdf_id"
                           :readonly="true"
                           v-if="pdf_id"
        />
        <div v-else>
          <p class="my-4">正在生成，请稍候!</p>
        </div>

      </b-modal>

      <b-modal
          id="selectWarehouse"
          ok-title="添加"
          cancel-title="不添加"
          centered
          title="打印添加店铺名称"
          ref="selectWarehouse"
          @ok="submitWarehouse"
          @cancel="clearInput"
          @close="closeInput"
      >
        <div>
          <b-col cols="12">
            <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label="店铺名称:"
                label-for="label"
                label-size="sm"
            >
              <v-select
                  id="status_id"
                  :options="warehouseChargeList"
                  :clearable="true"
                  v-model = "selectWarehouseCharge"
                  class="select-size-sm"
                  placeholder="请选择仓库"
                  :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>
          <br>
        </div>
        <br>
      </b-modal>

      <!--  loading  -->
      <div v-show="loadingModal"><xy-loading :placeholder="loadingPlaceholder"></xy-loading>  </div>

      <fee-charge-select ref="feeRef" :object-type=2 :object-id=allotoutbound_outbound_id :warehouse-id=warehouse_id_from :warehouse-id-from=warehouse_id_from :warehouse-id-to=warehouse_id_to></fee-charge-select>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, reactive, toRefs, onMounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  lodopWatermark
} from '@core/utils/filter'
import allotoutboundoutboundUseList from './allotoutboundoutboundUseList'
import allotoutboundoutboundStore from './allotoutboundoutboundStore'
import {useRouter} from "@core/utils/utils";
import {useToast} from "vue-toastification/composition";
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import allotoutboundStore from '@/views/apps/allotoutbound/allotoutboundStore'
import lodopStore from "@/views/apps/lodop/lodopStore";
import {getLodop} from "@/libs/LodopFuncs";
import XyLoading from "@/views/components/xy/XyLoading";
import FeeChargeSelect from "@/views/apps/feeloadsetting/FeeChargeSelect";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    AttachmentUpload,
    XyLoading,
    FeeChargeSelect
  },
  data() {
    return {
      outbound_warehouse:0,
      allot_outbound_id:0,
      outbound_id_id:0,
      selectWarehouseCharge:0,
      warehouseChargeList:[],
      loadingModal:false,
      loadingPlaceholder:"单据正在生成,请勿重复操作!",
      allotoutbound_outbound_id:0,
      warehouse_id_from:0,
      warehouse_id_to:0
    }
  },
  methods: {
    changeState: function(data) {
      const state = 1 - data.state
      store.dispatch('allotoutboundoutbound/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    //如果不添加
    clearInput: function() {
      this.selectWarehouseCharge = 0
      this.toLODOP(this.outbound_warehouse)
    },
    closeInput: function() {
      this.selectWarehouseCharge = 0
      this.warehouseChargeList= []
    },
  },
  setup() {
    // Register module
    if (!store.hasModule('allotoutboundoutbound')) store.registerModule('allotoutboundoutbound', allotoutboundoutboundStore)
    if (!store.hasModule('allotoutbound')) store.registerModule('allotoutbound', allotoutboundStore)
    if (!store.hasModule('lodop')) store.registerModule('lodop', lodopStore)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('allotoutboundoutbound')) store.unregisterModule('allotoutboundoutbound')
      if (store.hasModule('lodop')) store.unregisterModule('lodop')
    })
    onMounted(() => {
      state.condition = store.getters['allotoutbound/getCondition']
    })

    const state = reactive({
      condition:{
      },
      curRow:undefined,
      rollbackModal:undefined,
      pdf_id:undefined,
      pdfModal:undefined,
    })

    const router = useRouter()

    const toast = useToast()

    const editItem = function (data){
      this.$router.push({name: 'apps-allotoutboundoutbound-editItem', query: {id: data.id}});
    }

    const resoleProduce = function (data){
      this.$router.push({name: 'apps-allotoutboundoutbound-editItem', query: {id:data.id}});

    }

    const submitItem = function (data){
      if(confirm("确认提交出库吗？")){
        this.loadingModal = true
        store.dispatch('allotoutboundoutbound/submitItem', {
          outbound_id:data.outbound_id,
          allot_outbound_id:data.allot_outbound_id
        }).then(res => {
          this.loadingModal = false
          if(res.data.code==0){
            toast.success("提交成功");
            refetchData()
          }else{
            toast.error(res.data.data);
            refetchData()
          }
        })
      }
    }

    const submitItemAll = function (outbound_id,allot_outbound_id){
        this.loadingModal = true
        store.dispatch('allotoutboundoutbound/submitItem', {
          outbound_id:outbound_id,
          allot_outbound_id:allot_outbound_id
        }).then(res => {
          this.loadingModal = false
          if(res.data.code==0){
              this.selectWarehouseCharge = 0
              this.toLODOP(res.data.data.outbound_id)
          }else{
            toast.error(res.data.data);
          }
        })

    }

    const printItemPre = function (data){
      //点击打印时，先弹出选择店铺名称弹框  传一个 店铺ID或者直接传一个店铺Name
      this.outbound_warehouse = data
      store.dispatch('allotoutboundoutbound/warehouseCharge',{
        outbound_id:data
      }).then(res => {
        if (res.data.code === 0) {
          let  warehouseList = res.data.data

          for(let i = 0 ;i<warehouseList.length;i++  ){
            let obj={};
            obj.value=warehouseList[i].id;
            obj.label=warehouseList[i].name;
            this.warehouseChargeList.push(obj);
            // this.warehouseChargeList.push({"label": warehouseList[i].name, "value": warehouseList[i].id});
          }
          this.$refs['selectWarehouse'].show()
        } else {
          toast.error(res.data.data)
        }
      })
    }


    const printItem = function (data){
      this.warehouseChargeList= []
      //调用打印方法
      store.dispatch('allotoutboundoutbound/printItem', {
        outbound_id:data,
        warehouseId:this.selectWarehouseCharge
      }).then(res => {
        if (res.data.code === 0) {
          //清空仓库数组
          this.warehouseChargeList= []
          state.pdfModal.show()
          state.pdf_id = res.data.data.id
          this.$router.push({name: 'apps-allotoutboundoutbound-list', query: {id: this.allot_outbound_id}});
        } else {
          toast.error(res.data.data)
        }
      })
    }

    const toLODOP = function (params) {
      store.dispatch('lodop/createAllotOutboundOutboundLODOP', {
        outbound_id:params,
        warehouseId:this.selectWarehouseCharge
      }).then(res => {
        if (res.data.code === 0) {
          const data = res.data.data
          var LODOP = getLodop();
          if (LODOP == undefined) {
            toast.error("打印插件未安装，请安装插件!")
            return;
          }
          LODOP.PRINT_INIT('打印出库单') //打印初始化
          //LODOP.SET_PRINTER_INDEX(printer) //选择的打印机类型（注意这里是打印机下标，0开始的）
          LODOP.SET_PRINT_STYLE('FontSize', 18) //设置对象风格
          // this.LODOP.ADD_PRINT_TEXT(50, 521, 130, 39, this.description) //增加纯文本项
          LODOP.SET_PRINT_PAGESIZE(1, 2200, 1400, '') //设定纸张大小
          // LODOP.SET_PRINT_MODE('PRINT_PAGE_PERCENT', '55%')//设置缩放
          LODOP.SET_PREVIEW_WINDOW(2, 2, 0, 0, 0, '')//设置窗口
          var html = ''
          var item = ``
          var items = data.ext.item
          for (let j = 0; j < items.length; j++) {
            item += ` <tr height="23px">
                        <td align="center">${items[j].ext.no}</td>
                        <td align="center">${items[j].ext.code}</td>
                        <td align="left">${(items[j].name.length>28)?items[j].name.substring(0,28):items[j].name}</td>
                         <td align="center">${items[j].ext.production_date}</td>
                        <td align="center">${items[j].ext.specification}</td>
                        <td align="center">${items[j].ext.productUnit}</td>
<!--                        <td align="center">${items[j].qty}</td>-->
                        <td align="center">${items[j].trueqty}</td>
                        <td align="center">${items[j].memo==undefined?'':items[j].memo}</td>
                    </tr>`
          }
          html = `
                <div style="width:780px">
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr height="30px">
                        <td colspan="2">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr height="30px">
                                    <td width="50%">${data.ext.time}(第${data.print_times}次)</td>
                                    <td width="20%" align="center"><font face="楷体" size="4">${data.ext.warehouseName==undefined?'':data.ext.warehouseName}出库单</font></td>
                                    <td width="30%" align="right"></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr height="20px">
                        <td width="33%">收货单位：${data.towarehouse_name}</td>
                        <td width="33%">类型：${getCodeLabel('allot_type', data.ext.ao.allot_type)}</td>
                        <td width="33%">单据编号：${data.outbound_no}</td>
                    </tr>
                    <tr height="20px">
                        <td width="33%">发货仓库：${data.fromwarehouse_name}</td>
                        <td width="33%">计划日期：${data.allot_time==undefined?'':toDate(data.allot_time)}</td>
                        <td width="33%">出库时间：${data.outbound_time==undefined?'':toTime(data.outbound_time)}</td>
                    </tr>
                </table>
                <table width="100%" class="protable">
                    <tr height="25px">
                        <td width="4%" align="center">序号</td>
                        <td width="12%" align="center">69码</td>
                        <td align="center">商品名称</td>
                        <td align="center">生产日期</td>
                        <td width="8%" align="center">规格</td>
                        <td width="5%" align="center">单位</td>
<!--                        <td width="6%" align="center">计划数量</td>-->
                        <td width="7%" align="center">本次数量</td>
                        <td width="18%" align="center">备注</td>
                    </tr>
                    ${item}
                    <tr height="23px">
                        <td align="center"></td>
                        <td align="center">合计</td>
                        <td align="center"></td>
                         <td align="center"></td>
                        <td align="center"></td>
                        <td align="center"></td>
<!--                        <td align="center">${data.ext.allQty}</td>-->
                        <td align="center">${data.ext.allTrueQty}</td>
                        <td align="center"></td>

                    </tr>
                </table>
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr height="20px">
                        <td width="100%">通知备注：${data.ext.ao.memo==undefined?'':data.ext.ao.memo}</td>
                </tr>
                </table>

                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr height="20px">
                        <td colspan="3">
                          <table width="100%" border="0" cellspacing="0" cellpadding="0">
                              <tr height="25px">
                                <td width="34%">制单人：${data.ext.user}</td>
                                <td width="33%">仓库备注：${data.ext.ao.memo_ch==undefined?'':data.ext.ao.memo_ch}</td>
                                <td width="33%"></td>
                              </tr>
                          </table>
                        </td>
                    </tr>
                    <tr height="20px">
                        <td width="25%">车型：${data==undefined?'':data.car_detail==undefined?'':getCodeLabel('car_detail',data.car_detail)}</td>
                        <td width="25%">车牌号码：${data==undefined?'':data.car_no==undefined?'':data.car_no}</td>
                        <td width="25%">联系电话：${data==undefined?'':data.car_phone==undefined?'':data.car_phone}</td>
                        <td width="25%">司机签字：${data==undefined?'':data.car_driver==undefined?'':data.car_driver}</td>
                    </tr>
                    <tr height="50px">
                        <td>发货人签字：</td>
                        <td>审核人签字：</td>
                        <td>收货人签字：</td>
                    </tr>
                </table>
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr height="25px">
                        <td><font size="2px">**白联：存根|红联：客户|蓝联：财务|绿联：司机|黄联：仓管</font> **<font size="2px" style="font-weight: bold">友情提醒：货物请当面点清，事后恕不负责**</font></td>
                    </tr>
                </table>
                </div>
          `
          var style =
              "<style>" +
              "table{}" +
              "table td{font-size: 14px;white-space: nowrap;}" +
              ".protable{border-collapse: collapse;border: none;}" +
              ".protable td{border: solid #000 1px;}" +
              "</style>";
          lodopWatermark(LODOP)
          LODOP.ADD_PRINT_HTM(0, 0, 1500, 1500, style + '<body size="10px">' + html + '</body>') // 增加超文本项（打印内容即为body内容）
          LODOP.NewPage();
          LODOP.PREVIEW() // 设置预览（如果使用预览，每次打印前会弹出预览窗口，根据需要决定是否使用）
          this.refetchData()
          //清空仓库数组
          this.warehouseChargeList= []
        } else {
          toast.error(res.data.data)
        }
      })
    }


    const completeOutbound = function (data){
      if(confirm("手动修改出库状态,确定是否全部出库？")){
        store.dispatch('allotoutboundoutbound/completeOutbound', {id:this.allot_outbound_id}).then(res => {
          if (res.data.code === 0) {
            toast.success('出库完成!')
            this.$router.push({ name: 'apps-allotoutbound-warehouse',query: {id:this.allot_outbound_id}});
          } else {
            toast.error(res.data.data)
          }
        })
      }
    }
    const toURL = function (data){
      let result =true
      return "/apps/allotoutboundoutbound/editItem?id=" + data.outbound_id+"&isLook=1"

    }

    const submitWarehouse = function () {
       this.toLODOP(this.outbound_warehouse)
    }

    const showChargeModal = function (param,type){
      this.allotoutbound_outbound_id = param.id
      this.warehouse_id_from = param.fromwarehouse_id
      this.warehouse_id_to = param.towarehouse_id
      if(type === 1){
        this.$refs['feeRef'].showLoadChargeModal(param.fromwarehouse_id,2)
      }
      if(type === 2){
        this.$refs['feeRef'].showTransportChargeModal(param.fromwarehouse_id,param.towarehouse_id,2)
      }
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = allotoutboundoutboundUseList({
      allot_outbound_id: router.route.value.query.id
        }
    )

    return {
      ...toRefs(state),
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
      editItem,
      submitItem,
      printItem,
      resoleProduce,
      completeOutbound,
      submitItemAll,
      toURL,
      submitWarehouse,
      printItemPre,
      toLODOP,
      showChargeModal,
    }
  },
  created() {
      this.allot_outbound_id = this.$route.query.id || 0
      this.outbound_id_id=this.$route.query.pdf_id || 0;
      if(this.outbound_id_id!=0){
        this.submitItemAll(this.outbound_id_id,this.allot_outbound_id)
      }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>



<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
